<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :mask-closable="false"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="编码" v-bind="validateInfos.code" v-if="!form.id">
        <a-input v-model:value="form.code" placeholder="请输入编码" />
      </a-form-item>
      <a-form-item label="名称" v-bind="validateInfos.name">
        <a-input v-model:value="form.name" placeholder="请输入名称" />
      </a-form-item>
      <a-form-item label="状态">
        <a-switch
          v-model:checked="form.status"
          checked-children="启用"
          un-checked-children="禁用"
        />
      </a-form-item>
      <a-form-item label="描述">
        <a-textarea v-model:value="form.remark" placeholder="请输入描述" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRaw, watch } from 'vue';
import { Form, Switch } from 'ant-design-vue';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const form = reactive({
      id: '',
      code: '',
      name: '',
      status: true,
      remark: '',
      dsType: { code: 'SELF' },
    });
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      code: [required],
      name: [required],
    });
    const handleCancel = () => context.emit('update:visible', false);
    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
        }
      }
    );

    return {
      form,
      validateInfos,
      handleCancel,
      handleOk: () => {
        validate().then(() => {
          context.emit('fnOk', toRaw(form));
          handleCancel();
        });
      },
    };
  },
};
</script>
